import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from "firebase";
const firebaseConfig = {
    apiKey: "AIzaSyAg9EIYpcN2CNWfBkCeLtchMihPhlMeRV0",
    authDomain: "fire-e27e3.firebaseapp.com",
    databaseURL: "https://fire-e27e3.firebaseio.com",
    projectId: "fire-e27e3",
    storageBucket: "fire-e27e3.appspot.com",
    messagingSenderId: "196867393797",
    appId: "1:196867393797:web:cbebb529ab9813b8048f6c"
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
